import React, {Component} from "react";

import * as Pages from "../LocationForm";

import "../../../stylesheets/location-selection.scss";

import {Vector} from "ol/layer";
import {
	fromLonLat,
	toLonLat,
} from "ol/proj";
import {
	extractAddressComponents,
	getAddressFromLatLng,
	getLatLngFromAddress,
} from "../../../utilities/utilities";
import VectorSource from "ol/source/Vector";
import {
	Style,
	Stroke,
	Fill,
} from "ol/style";

import {
	toNameCase,
	getOLFeature,
	generateBoundaryLayers,
} from "../../../utilities/utilities";
import {ToggleLayerModal} from "../../parts/Modals";


export class LocationWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedFeature: null,
			modalIsOpen: false,
		};

		this.vectorLayers = [];

		this.vectorSource = new VectorSource();

		this.vectorLayers.push(
			new Vector({
				source: this.vectorSource,
				style: new Style({
					stroke: new Stroke({
						color: "rgba(0,0,255)",
						width: 2,
					}),
					fill: new Fill({
						color: "rgba(0,0,255,0.375)",
					}),
				}),
			})
		);
		this.boundaryLayers = generateBoundaryLayers();

		this.layers = [
			...this.boundaryLayers,
			...this.vectorLayers,
		];
		this.feature = null;
		this.map = null;
	};

	toggleModal = (modalIsOpen = false) => {
		this.setState({
			modalIsOpen: modalIsOpen,
		});
	};

	onMapMount = (map) => {
		this.map = map;
	};

	onMapClick = async (event) => {
		let coordinates = event.coordinate;
		coordinates = toLonLat(coordinates);
		let position = {
			longitude: coordinates[0],
			latitude: coordinates[1],
		};
		await this.handleGeolocation(position);
	};

	handleBadResponse = (response) => {
		let warning = "";

		if (
			response.status.includes("ZERO_RESULTS") ||
			response.status.includes("INVALID_REQUEST")
		) {
			warning = "No results were returned or this" +
				" address does not exist. Please try a" +
				" different address.";
		} else if (
			response.status.includes("OVER_DAILY_LIMIT") ||
			response.status.includes("OVER_QUERY_LIMIT") ||
			response.status.includes("REQUEST_DENIED")
		) {
			warning = "The geocoding service is" +
				" currently unavailable.";
		} else if (
			response.status.includes("TOO_BROAD")
		) {
			warning = "This address is too vague. Please try" +
				" one that is more specific.";
		} else {
			warning = "There has been an error while " +
				"geocoding this address. Please try again.";
		}

		this.setState({
			warning: warning
		});
		this.toggleModal(true);
	};

	handleGeolocation = async (position, address = null) => {
		document.body.style.cursor = "";

		// Removes selected feature before adding new a feature
		// to avoid displaying reports for the wrong feature.
		if (this.feature) {
			this.vectorSource.removeFeature(this.feature);
		}

		// Remove some extraneous + and leading 0s.
		let coordinates = this.correctCoordinates({
			latitude: position.latitude,
			longitude: position.longitude,
		});

		// Get the full address in order to get all components.
		let addressFromLatLng = await getAddressFromLatLng(coordinates);

		if (addressFromLatLng.status.toLowerCase() === "ok") {
			// Pull out the individual address components.
			let components = extractAddressComponents(
				addressFromLatLng.results[0]
			);

			if (components.nation.includes("united states")) {
				// Creates an address if there isn't one, usually
				// as a result from clicking on the map.
				if (!address) {
					let city = components.city.long;
					let state = components.stateAbbr.toUpperCase();
					address = `${toNameCase(city)}, ${state}`;
				}

				// Convert the position for use on the map.
				let positionFromLatLng = fromLonLat(
					[
						position.longitude,
						position.latitude
					]
				);

				// Try to get a Place first.
				let boundaryLayerIndex = 0;
				let resolution = this.map.getView().getResolution();
				let olFeature = await getOLFeature({
					source: this.boundaryLayers[boundaryLayerIndex].getSource(),
					coordinates: positionFromLatLng,
					resolution: resolution,
				});

				// If we failed to get a Place, try for a Subdivision.
				if (!olFeature) {
					boundaryLayerIndex = 1;
					olFeature = await getOLFeature({
						source: this.boundaryLayers[boundaryLayerIndex].getSource(),
						coordinates: positionFromLatLng,
						resolution: resolution,
					});
				}

				// If Subdivision or Place, proceed,
				// otherwise open the warning modal.
				if (olFeature) {
					this.props.updateGeoid(olFeature.get("title"));
					this.vectorSource.addFeature(olFeature);
					this.feature = olFeature;
					this.map.getView().setCenter(positionFromLatLng);
					this.changeBoundaryLayerVisibility(boundaryLayerIndex);

					// Do state updates as per usual.
					this.props.updateProject({
							location: {
								address: {
									entered: address,
									formatted: address,
								},
								latitude: coordinates.latitude,
								longitude: coordinates.longitude,
							},
						},
					);
				} else {
					this.toggleModal(true);
				}
			}
		} else {
			this.handleBadResponse(addressFromLatLng);
		}
	};

	correctCoordinates = (coordinates) => {
		let {latitude, longitude} = coordinates;
		latitude = latitude.toString();
		longitude = longitude.toString();
		if (latitude.includes("+")) latitude = latitude.replace("+", "");
		if (longitude.includes("+")) longitude = longitude.replace("+", "");
		if (latitude.includes("-0")) latitude = latitude.replace("-0", "-");
		if (longitude.includes("-0")) longitude = longitude.replace("-0", "-");
		coordinates.latitude = parseFloat(latitude);
		coordinates.longitude = parseFloat(longitude);
		return coordinates;
	};

	handleAddressSearch = async (address) => {
		let latLngFromAddress = await getLatLngFromAddress(address);

		if (latLngFromAddress.status.toLowerCase() === "ok") {
			let result = latLngFromAddress.results[0];
			let position = {
				longitude: result.geometry.location.lng(),
				latitude: result.geometry.location.lat()
			};
			await this.handleGeolocation(position, address);
		} else {
			this.handleBadResponse(latLngFromAddress);
		}
	};

	changeBoundaryLayerVisibility = (layerIndex) => {
		// Update the state and adjust layer visibility.
		this.props.updateBoundaryLayerIndex(layerIndex);
		this.boundaryLayers.forEach((layer, index) => {
			layer.setVisible(index === layerIndex);
		});
	};

	render() {
		let {longitude, latitude} = this.props.location;
		let locationParameters = {
			headline: "Where is your community?",
			navButtonParameters: {
				path: `report?longitude=${longitude}&latitude=${latitude}&tab=benefits`,
				text: "Get Results!",
			},
			subAddressbarText: "For the CONTINENTAL U.S., enter a city and" +
				" state or a zip code above or click on the map below.",
		};
		let mapParameters = {
			imagerySets: [
				{name: "RoadOnDemand", visible: true},
			],
			layers: this.layers,
			activeImagery: 0,
			zoom: 10,
			maxZoom: 15,
		};

		return (
			<React.Fragment>
				<Pages.LocationForm
					{...this.props}
					{...locationParameters}
					{...mapParameters}
					location={this.props.location}
					infoSubject={"mapPage"}
					activeBoundaryLayerIndex={this.props.activeBoundaryLayerIndex}
					onMapClick={this.onMapClick}
					onMapMount={this.onMapMount}
					handleGeolocation={this.handleGeolocation}
					handleAddressSearch={this.handleAddressSearch}
					changeBoundaryLayerVisibility={this.changeBoundaryLayerVisibility}
					updateProject={this.props.updateProject}
				/>
				<ToggleLayerModal
					modalIsOpen={this.state.modalIsOpen}
					toggleModal={this.toggleModal}
				/>
			</React.Fragment>
		);
	};
}
