import React, {Component} from "react";

import {
	Row,
	Col,
	Container,
} from "reactstrap"

import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {CompanyInfo} from "../parts/GeneralPageElements";
import {
	NavButton,
	ModalButton
} from "../parts/Buttons";
import {ProjectList} from "../parts/ProjectList";

import logo from "../../img/iTree_primary_logo_restricted_MEDIUM.png";


export const Dashboard = (
	{
		history,
		projects,
		getAllProjects,
		deleteProject,
		initializeProject,
		retrieveProject,
		setMessage,
		reset,
	}
) => {
	const resetFunction = () => {
		history.push("/");
		reset();
	};

	const handleNavigation = (e) => {
		e.preventDefault();

		if (projects.length <= 0) {
			setMessage(
				"Calculate All",
				"At least one tree must be entered" +
				" before benefits can be calculated.",
				true
			)
		} else if (projects.length === 1) {
			retrieveProject(
				projects[0].uuid,
				() => {
					history.push("/report/individual?era=forecast&type=benefits",);
				}
			);
		} else {
			history.push("/report/total?era=forecast&type=benefits");
		}
	};

	const editProject = (uuid) => {
		retrieveProject(
			uuid,
			() => {
				history.push("/tree")
			}
		);
	};

	let warning = false;
	for (let pj in projects) {
		if (projects[pj].warning.length) warning = true;
	}

	return (
		<Container>
			<Row>
				<Col className={"mt-1 mt-xl-3 text-center"}>
					<h3>Your List of Trees</h3>
				</Col>
			</Row>
			<Row>
				<Col className={"d-flex justify-content-around flex-wrap flex-md-nowrap"}>
					<NavButton
						className={"mb-3 round max-content"}
						path={"/location"}
						color={"success"}
						text={"Add Another Tree"}
						leftIcon={"plus"}
						onClick={initializeProject}
						title={"Add another tree."}
					/>
					<NavButton
						className={"mb-3 round max-content"}
						disabled={projects.length <= 0}
						path={`/report/${projects.length <= 1 ? "individual" : "total"}`}
						text={"Calculate All Trees"}
						leftIcon={"calculator"}
						onClick={handleNavigation}
						title={"Calculate this tree's benefits."}
					/>
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col
					className={"mb-3"}
					xs={{size: 12}}
					md={{size: 10, offset: 1}}
					lg={{size: 8, offset: 2}}
					xl={{size: 6, offset: 3}}
				>
					<ProjectList
						projects={projects}
						deleteProject={deleteProject}
						getAllProjects={getAllProjects}
						editProject={editProject}
						retrieveProject={retrieveProject}
						setMessage={setMessage}
					/>
				</Col>
			</Row>
			<Row>
				<Col className={"mb-3 text-center"}>
					<ModalButton
						className={"round max-content"}
						color={"danger"}
						disabled={projects.length <= 0}
						leftIcon={<FAI icon={"trash"}/>}
						outline={true}
						subject={"reset"}
						text={"Start Over"}
						title={"Remove all trees and input data."}
						closeColor={"secondary"}
						closeOutline={false}
						closeText={"Cancel"}
						confirmation={true}
						confirmationText={"Yes, start over"}
						confirmationColor={"danger"}
						confirmationOutline={true}
						confirmationFunction={resetFunction}
					/>
				</Col>
			</Row>
			<Row className={"mt-5 text-center"}>
				<Col>
					<p>
						{/*FIXME: This text is hidden, pending refactoring of the MyTreeMap app and Engine.*/}
						{/*Use MyTree to manage tree planting.*/}
						{/*<br/>*/}
						{/*Visit{" "}*/}
						{/*<a*/}
						{/*	href={"https://trilliontrees.itreetools.org"}*/}
						{/*	target={"_blank"}*/}
						{/*	rel={"noopener noreferrer"}*/}
						{/*>*/}
						{/*	MyTreeMap*/}
						{/*</a>*/}
						{/*{" "}to see these trees on a map.*/}
					</p>
				</Col>
			</Row>
			<Row className={"text-center"}>
				<Col>
					<img
						className={"mb-3 ms-n4"}
						alt={"i-Tree Logo"}
						height={"125"}
						src={logo}
					/>
				</Col>
			</Row>
			<CompanyInfo/>
		</Container>
	)
}
